import * as dateFns from 'date-fns';
import statuses from '../constants/statuses';
import {
  ABO,
  Rhd,
  DONOR_STATUS_DETAILS,
  DONOR_STATUS_SHOW_UP,
  RECURRENCE_TYPE, 
  WEEKDAYS_LIST, 
  WEEKDAYS_RULE,
} from '../components/Configuration/Modals/constants';
import i18n from '../i18n';
import * as donorStatus from '../constants/donorStatuses';
import { useEffect, useRef } from 'react';
import { DateTime } from 'luxon';

export const getTimeslotDateForCurentDay = (mainDate, currentDate) => {
  const currentDateObj = new Date(currentDate);
  const hours = dateFns.getHours(new Date (mainDate));
  const minutes = dateFns.getMinutes(new Date (mainDate));
  return dateFns.set(currentDateObj, {hours, minutes}).getTime();
}
export const getDonorBloodType = (donor) => {
  if (donor && donor.donorType) {
    const bloodType = ABO.find((item) => item.value === donor.donorType.bloodType);
    const rhesus = Rhd.find((item) => item.value === donor.donorType.rhesus);
    if (bloodType && rhesus) {
      return `${bloodType.label}${rhesus.label}`;
    }
    return '-';
  }
  return '-';
};

export const getDonorStatus = (donor) => {
  return (donor && donor.status) ? 
  i18n.t(`common.donorStatus.${(donor.status === "new_registration" ? DONOR_STATUS_DETAILS[0].value : donor.status)}`) : 
    '-'
};

export const formatMilliseconds = milliseconds => {
  const hours = new Date(milliseconds).getUTCHours();
  return `${hours ? `${hours}h ` : ''}${new Date(milliseconds).getUTCMinutes()}m`
};

export const capitalize = text => text?.length ? text.charAt(0).toUpperCase() + text.slice(1) : '';

export const getStatusStyles = (status) => {
  switch (status) {
    case statuses.PENDING:
      return 'available';
    case statuses.NEED_CONFIRMATION:
    case statuses.CONFIRMED:
      return 'not-available';
    case statuses.DID_ATTEND:
    case statuses.DID_NOT_ATTEND:
    default:
      return 'inactive';
  }
};

export const getBloodType = (bloodTypes) => {
  const bloodTypeStrings = bloodTypes.map(bloodType => {
    const {bloodType: bloodGroup, rhesus} = bloodType;
    return `${bloodGroup.toUpperCase()}${rhesus === 'plus' ? '+' : '-'}`;
  });
  return bloodTypeStrings.join(', ');
};

export const bloodTypesToTags = (bloodTypes) => {
  return bloodTypes.map(item => {
    const aboData = ABO.find(abo => item.bloodType === abo.value);
    const rhesusData = Rhd.find(rhd => item.rhesus === rhd.value);
    return {
      value: item,
      label: `${aboData.label}${rhesusData.label}`,
    }
  })
};

export const filtersToTags = (filters, t, pathToName) => {
  return filters?.map(value => {
    return {
      value: value,
      label: t(`${pathToName}${value}`),
    }
  })
};

export const filterDateToTags = (filters)=>{
  return filters?.map(value => {
    return {
      value: value,
      label: value,
    }
  })
}
export const getNextDonorStatus = status => {
  // eslint-disable-next-line
  switch (status.value) {
    case donorStatus.NEW:
      return DONOR_STATUS_SHOW_UP.find(item => item.value === 'approved');
    case donorStatus.APPROVED:
    case donorStatus.ESTABLISHED:
      return DONOR_STATUS_SHOW_UP.find(item => item.value === 'established');
  }
};

export function createUTC(date) {
    const tempDate = new Date(date);
    return new Date(Date.UTC(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), tempDate.getHours(), tempDate.getMinutes()));
}

export const getStartOfAllDay = (date) => new Date((new Date(date)).setHours(0,0,0,0));

export const getStartOfWorkDay = (date) => dateFns.setHours(dateFns.startOfDay(date || new Date()), 6).getTime();

export const getEndOfWorkDay = (date) => dateFns.setHours(dateFns.startOfDay(date || new Date()), 22).getTime();

export const getTimeIntervals = (date) => {
  const startOfWorkDay = getStartOfWorkDay(date);
  const endOfWorkDay = getEndOfWorkDay(date);
  const dateFormat = "HH:mm";
  const interval = 5;

  let time = startOfWorkDay;
  let hours = [];
  let formattedDate = "";

  const differenceInTime = endOfWorkDay - startOfWorkDay;
  const timeSpots = differenceInTime / interval / (1000 * 60);
  if (dateFns.compareDesc(time, endOfWorkDay) > 0) {
    for (let i = 0; i <= timeSpots; i++) {
      formattedDate = dateFns.format(time, dateFormat);
      const obj = {
        value: formattedDate,
        label: formattedDate,
        id: i + 1,
      };
      hours.push(obj);
      time = dateFns.addMinutes(time, interval);
    }
  }
  return hours;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }); // maybe we need to add value to deps
  return ref.current;
};

export const getResourceName = (number) => {
  return (number < 10 ? '0' : '') + number
};

export const isAppIsoFormat = (dateString) => {
  if (typeof dateString !== 'string') return false;
  const parsedDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd HH:mm:ss', { zone: 'Europe/Oslo' });
  return parsedDate.isValid && parsedDate.toFormat('yyyy-MM-dd HH:mm:ss') === dateString;
};

export const isoToTimestamp = (isoDate) => {
  try {
    if (isAppIsoFormat(isoDate)) {
      const parsedDate = DateTime.fromFormat(isoDate, 'yyyy-MM-dd HH:mm:ss', {
        zone: 'Europe/Oslo',
        setZone: true,
      });
      return parsedDate.toMillis();
    }
    else {
      return isoDate;
    }
  } catch (error) {
    console.log(error)
  }
};

export const parseToDateString = (time, mask = "d MMMM yyyy") => {
  try {
    if (isAppIsoFormat(time)) {
      const parsedDate = DateTime.fromFormat(time, 'yyyy-MM-dd HH:mm:ss', {
        zone: 'Europe/Oslo',
        setZone: true,
      });
      return parsedDate.toFormat(mask);
    }
    else {
      return dateFns.format(time, mask)
    }
  } catch (error) {
    console.log(error)
  }
}

export const timestampToIso = timestamp => {
  if (timestamp) {
    try {
      const dateObj = DateTime.fromMillis(timestamp, { zone: 'Europe/Oslo' });
      if (dateObj.isValid) {
        return dateObj.toFormat('yyyy-MM-dd HH:mm:ss');
      }
      else{
        return timestamp
      }
    } catch (error) {
      if (typeof timestamp === 'string' && isAppIsoFormat(timestamp)) {
        return timestamp;
      } else {
        console.error("Invalid timestamp or format:", error);
      }
    }
  }
}

export const timestampToWorkingTime = (timestampInMilliseconds) => {
  const timeIntervals = getTimeIntervals();
  const timeFormat = "HH:mm";
  const formattedTimeTo = dateFns.format(new Date(timestampInMilliseconds), timeFormat);
  return timeIntervals.find(item => item.value === formattedTimeTo) || timeIntervals[0];
};

export const workingTimeToTimestamp = (workingTime, date) => {
  return dateFns.parse(workingTime.value, "HH:mm", date || new Date()).getTime()
};

export const getRuleDescription = rule => {
  const {freq, interval, byweekday = [], bymonthday = [], weekNumber, count, until} = rule;

  const getDays = byweekday ? ` on ${WEEKDAYS_LIST.filter(item => byweekday.includes(parseInt(item.value))).map(item => item.label).join(', ')}` : ``;
  const getNumberWithSuffix = dayNumber => {
    const i = dayNumber % 10,
      j = dayNumber % 100;
    if (i === 1 && j !== 11) return dayNumber + "st";
    if (i === 2 && j !== 12) return dayNumber + "nd";
    if (i === 3 && j !== 13) return dayNumber + "rd";
    return dayNumber + "th";
  };
  const getMonthDayRule = weekNumber
    ? `${WEEKDAYS_RULE.find(item => parseInt(item.value) === weekNumber).label} ${WEEKDAYS_LIST.find(item => parseInt(item.value) === byweekday[0]).label}`
    : getNumberWithSuffix(bymonthday[0]);

  const dateFormat = "dd MMMM yyyy";
  const untilDate = until ? ` until ${dateFns.format(new Date(until), dateFormat)}` : ``;
  const forNTimes = count ? ` for ${count} times` : ``;
  const endCondition = untilDate || forNTimes || ``;

  // eslint-disable-next-line
  switch (freq) {
    case RECURRENCE_TYPE.DAILY:
      return `Every ${interval > 1 ? `${interval} days` : `day`}${endCondition}`;
    case RECURRENCE_TYPE.WEEKLY:
      return `Every ${interval > 1 ? `${interval} weeks` : `week`}${getDays}${endCondition}`;
    case RECURRENCE_TYPE.MONTHLY:
      return `Every ${interval > 1 ? `${interval} months` : `month`} on the ${getMonthDayRule}${endCondition}`;
  }
};

export const getAllWeekdayOccurrencesInMonth = date => {
  const startOfMonth = dateFns.startOfMonth(date);
  const dayNumber = date.getDay();
  const monthNumber = date.getMonth();
  const sameWeekdayDates = [];
  const daysToFirst = (dayNumber + 7 - startOfMonth.getDay()) % 7;
  const firstOf = new Date(startOfMonth.setDate(startOfMonth.getDate() + daysToFirst));
  while (firstOf.getMonth() === monthNumber) {
    sameWeekdayDates.push(new Date(+firstOf));
    firstOf.setDate(firstOf.getDate() + 7);
  }
  return sameWeekdayDates
};

export const getNumberOfOccurrenceInMonth = date => {
  return Math.floor((date.getDate() - 1) / 7) + 1;
};

export const checkRecurrence = (selectedTimeslot) => {
  if (selectedTimeslot) {
    const { initialSettings } = selectedTimeslot;
    return !!initialSettings; 
  }
  return true;
}

export const checkSingleAppointment = (selectedTimeslot) => {
  if (selectedTimeslot) {
    const { startDate, endDate, appointmentType: {duration} } = selectedTimeslot;
    return !!(endDate - startDate === duration);
  }
  return true;
}

export const checkRecurrenceRule = (rule) => {
  if (!!rule) {
    if (Object.keys(rule).length === 1 && rule.freq === "DAILY") {
      return false;
    }
    return true;       
  } 
  return false;   
}

export const isDateGreaterThanToday = (timestamp) => {
  if (!timestamp) return false
  const today = new Date().setHours(0, 0, 0, 0);
  const inputDate = new Date(timestamp).setHours(0, 0, 0, 0);
  return inputDate > today;
}

export const getAbortController = () => {
  const abortController = new AbortController();
  const { signal } = abortController;
  return { signal, abortController };
}

export class ApiError extends Error {
  constructor(message, status, url) {
    super(message);
    this.status = status;
    this.url = url;
  }
}