import React, {useEffect, useState} from 'react';
import TetherComponent from 'react-tether';

import Slot from './Slot'
import TimeSlotMenu from './TimeSlotMenuContainer';
import OutsideClickHandler from "../common/OutsideClickHandler";
import { useTranslation } from 'react-i18next';
import { 
  getDonorBloodType, 
  getDonorStatus,
} from '../../services/helpers';

const TimeSlotGroup = (props) => {
  const {
    scrollableArea,
    type,
    timeslotsDelay,
    timeslotEndDate,
    bloodTiter,
    bloodTypes,
    onClick,
    donorStatus,
    appointmentDuration,
    totalDuration,
    timeslotMenuIsOpened,
    setTimeslotMenuIsOpen,
    data,
    startDowntime
  } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [downtimeShift, setDowntimeSthift] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (!timeslotMenuIsOpened) {
      setMenuOpen(false);
    }
  }, [timeslotMenuIsOpened]);

  useEffect(() => {
    if (isMenuOpen) {
      setTimeslotMenuIsOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    onClick();
  };


  const renderTimegroup = () => {


    const slot =
      <div className ='timeslot'>
        <Slot
          size={`duration-${appointmentDuration}`}
          name={`${type} (${appointmentDuration > 60 ?  `1h 30` : appointmentDuration}m)`}
          type={type}
          bloodTypes={bloodTypes}
          donorStatus={donorStatus}
          isConfiguration
          bloodTiter={bloodTiter}
        />
      </div>;

    const downtime = (minutes) => {
      const downtimeClassName = `slot downtime duration-${minutes}`;
      return <div className ='timeslot'>
        <div className={downtimeClassName}>{`${t('configuration.downtime')} (${minutes}m)`}</div>
      </div>;
    };

    const sortedAppointments = data.sort((item1, item2) => item1.startDate - item2.startDate)
    const timegroup = [];
    sortedAppointments.forEach((appointment, index) => {

      if (index === 0 && startDowntime > 0) {
        timegroup.push(downtime(startDowntime));
      }

      if(!appointment.reservation) {
        timegroup.push(slot);
      } else {
        const currentBloodType = getDonorBloodType(appointment.reservation.user);
        const currentDonorStatus = getDonorStatus(appointment.reservation.user);
        const userTitle = (appointment.reservation.user === null) ? 
          '######' : 
          (currentDonorStatus !== '-' && currentBloodType !== '-') ? 
          `${currentDonorStatus} donor, ${currentBloodType}` 
          : '';

        timegroup.push (
        <div className ='timeslot'>
          <Slot
            size={`duration-${appointmentDuration}`}
            name={`${type} (${appointmentDuration > 60 ?  `1h 30` : appointmentDuration}m)`}
            type={type}
            bloodTypes={bloodTypes}
            donorStatus={donorStatus}
            title={userTitle}
            reservationStatus={appointment.status}
            isConfiguration
            bloodTiter={bloodTiter}
          />
      </div>)
      }
      const nextAppointment = sortedAppointments[index + 1];
      const downtimeDuration = nextAppointment
        ? Math.round(nextAppointment.startDate - appointment.endDate)/60000
        : Math.round(timeslotEndDate - appointment.endDate)/60000;

      if (!nextAppointment) {
        setDowntimeSthift(Math.round(timeslotEndDate - appointment.endDate)/60000);
      }

      if (downtimeDuration) {
        timegroup.push(downtime(downtimeDuration));
      }
    });

    return timegroup;
  };

  const menuClassName = isMenuOpen ? 'slot-menu menu open' : 'slot-menu menu';
  const slotGroupClassName = `slot-group timeslot delay-${(startDowntime > 0) ? timeslotsDelay - startDowntime : timeslotsDelay} duration-${totalDuration} downtime-shift-${downtimeShift}`;

  return (
    <>
      <TetherComponent
        attachment="top left"
        targetAttachment="middle right"
        constraints={ [
          {
            to: scrollableArea,
            attachment: 'together',
            pin: true
          },
        ] }
        renderTarget={ ref =>
          <div ref={ ref } className={slotGroupClassName} onClick={ toggleMenu }>
              {renderTimegroup()}
          </div>
        }

        renderElement={ ref => (
          isMenuOpen && (
            <div ref={ ref } className={ menuClassName }>
              <OutsideClickHandler handleClickOutside={toggleMenu} closeOnScroll={true}>
                <TimeSlotMenu />
              </OutsideClickHandler>
            </div>
          )
        ) }
      />
    </>
  );
};

export default TimeSlotGroup;