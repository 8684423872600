import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { loadState, saveState } from './localStorage';
import rootReducer from '../redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { verifyAuth } from '../redux/auth/actions';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from '../firebaseConfig';

export const history = createBrowserHistory();

const middlewares = [
  thunkMiddleware,
];
const composeEnhancers = composeWithDevTools({
  trace: process.env.NODE_ENV === 'development',
  traceLimit: 25
});
const persistedState = loadState();
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(...middlewares)));

store.subscribe(() => {
  saveState({
    authReducer: {
      idToken: store.getState().authReducer.idToken, 
      expTimestamp: store.getState().authReducer.expTimestamp,
      currentBloodbank: store.getState().authReducer.currentBloodbank, 
      bloodbankName: store.getState().authReducer.bloodbankName, 
      allBloodbanks: store.getState().authReducer.allBloodbanks,
      userId: store.getState().authReducer.userId,
      roleName: store.getState().authReducer.roleName, 
      imageRef: store.getState().authReducer.imageRef,
    },
    pushMessagesReducer: {
      selectedFilters: Object.keys(store.getState().pushMessagesReducer.selectedFilters).length === 5 ? 
        store.getState().pushMessagesReducer.selectedFilters : 
        {
          donorTypes: [],
          bloodTiters: [],
          donorStatuses: [],
          appUserStatuses: [],
          smsStatuses: [],
          quarantineDue: [],
        },
    }
  });
});

firebase.initializeApp(firebaseConfig);
store.dispatch(verifyAuth());

export const db = firebase.firestore();
export default store;
