 const noTranslation = {
  header: {
    day: 'Dag',
    week: 'Uke',
    month: 'Mnd',
    administrator: 'Administrator',
    available: 'Tilgjengelig',
    logout: 'Logg ut',
    changeBloodbank: 'Endre blodbank',
    changePassword: 'Endre passord',
    changePhone: 'Endre telefonnummer',
    unknownBloodbank: 'Ukjent blodbank',

    tooltip: {
      configuration: 'Innstillingene',
      week: 'Uke',
    }
  },

  auth: {
    username: 'Brukernavn',
    enterEmailOrUsername: 'Skriv inn e-postadresse eller brukernavn',
    password: 'Passord',
    enterPassword: 'Skriv inn passord',
    forgotPassword: 'Glemt passord',
    login: 'Logg inn',
    enterCorrectUsername: 'Brukernavn er feil',
    enterCorrectPassword: 'Passordet er feil',
    enterEmailToRecover: 'Vennligst oppgi e-post addresse for å gjenopprette ditt passord',
    enterEmail: 'Oppgi e-post',
    email: 'E-post',
    emailNotExist: 'E-post adressen eksisterer ikke',
    recoverPassword: 'Gjenopprett passord',
    createNewPassword: 'Lag nytt passord',
    changeBloodbank: 'Velg andre blodbank',
    changePassword: 'Endre gjeldene passord',
    currentPassword: 'Gjeldende passord',
    enterCurrentPassword: 'Oppgi gjeldende passord',
    newPassword: 'Nytt passord',
    enterNewPassword: 'Oppgi nytt passord',
    incorrectPassword: 'Passordet er feil',
    invalidPassword: 'Må inneholde minst 8 tegn, inkludert tall, store og små bokstaver',
    identicalPassword: 'Passord er like',
    confirm: 'Bekreft',
    code: 'Kode',
    enterCodeToProceed: 'Vennlgst oppgi koden som har blitt sendt til ditt telefonnummer.',
    enterCode: 'Oppgi kode',
    enterYourCode: 'Oppgi kode',
    invalidCode: 'Ugyldig kode',
    codeWasResent: 'Ny kode er sendt til ditt telefonnummeret',
    phoneWasChanged: 'Telefonnummer endret',
    changePhoneNumber: 'Endre telefonnummer',
    newPhoneNumber: 'Nytt telefonnummer',
    enterPhoneNumber: 'Oppgi telefonnummer',
    invalidPhoneNumber: 'Ugyldig telefonnummer',
    resentCode: 'Har du ikke motttatt kode, vennligst klikk',
    here: 'her',
    enterYourCountry: 'Oppgi land',
  },

  configuration: {
    resourceDeleteModal: {
      header: 'Fjern ressurs',
      body1: 'Du kan ikke slette en ressurs med den eksisterende tidsluken på den!',
      body2: 'Er du sikker på at du vil slette denne ressursen?',
      delete: 'Fjern',
    },
    resourceEditModal: {
      header: 'Endre ressurs',
      body: 'Velg nytt navn for denne ressursen (hvis tom vil indeksen bli brukt)',
      input: 'Ressursnavn',
      edit: 'Endre',
    },
    deleteResource: 'Fjern ressurs',
    editResource: 'Endre ressurs',
    template: 'Velg mal',
    Plain: 'Enkel',
    newResource: 'Ny ressurs',
    resourceLimit: 'Maks antall ressurser er nådd',
    editTimeslot: 'Endre tidsbolker',
    deleteAppointment: 'Fjern avtale',
    bookAppointment: 'Book time',
    cancelBooking: 'Avbestill booking',
    deleteTimeslot: 'Fjern tidsbolker',
    downtime: 'Nedetid',
    settings: {
      header: 'Innstillinger for tidsbolker',
      appointmentDuration: 'Avtalevarighet',
      allBloodTypes: 'Alle blodtyper',
      selectBloodType: 'Vennligst velg blodtype',
      from: 'Fra',
      to: 'Til',
      startDate: 'Start dato',
      startTime: 'Start tid',
      endTime: 'Slutt tid',
      shouldBeBeforeEnd: 'Bør forekomme før sluttidspunkt',
      shouldBeAfterStart: 'Bør forekomme etter starttidspunkt',
      shouldBeGraterThanDuration: 'Bør ikke være mindre enn avtalt varighet',
      shouldBeLessThanTheEndTime: 'Start tiden må være før slutt tid',
      shouldNotBeLessThanDuration: 'Tidslengden må være lengre enn avtalevarighet',
      allDay: 'Hele dagen',
      recurring: 'Gjentakende',
      exceptions: 'Unntak',
      add: 'Legg til',
      discard: 'Forkast',
      edit: 'Endre',
      remove: 'Fjern',
      change: 'Endre',
      titer: {
        allVariants: 'Alle varianter',
        low: 'Lav',
        high: 'Høy',
        unknown: 'Ukjent',
        title: 'Titer (anti -A og -B)',
      },
      discardModal: {
        header: 'Forkast endringer?',
        body: 'Alle endringer vil bli forkastet. Er du sikker på at du vil fortsette?',
      },
      conflictModal: {
        header: 'Konflikt med andre tidsbolker',
        body: 'Den nye tidsbolken strider med eksisterende tidsbolk på ressurs {{resourceIndex}}. Du kan endre innstillingene eller opprette tidsbolken i en annen ressurs.',
      },
      noResourcesModal: {
        header: 'Ingen tilgjengelige ressurser',
        body: 'Ingen tilgjengelige ressurser. Vennligst endre tidsbolk instillingene.',
      },
      cancelAppointmentsModal: {
        header: 'Kanseller avtaler',
        body: 'Vennligst canceller alle kommende avtaler før endringene kan gjennomføres.',
      },
      deleteTimeslotModal: {
        header: 'Fjern tidsbolker',
        body1: 'Alle kommende reservasjoner for tidsbolken vil bli kansellert. Er du sikker på at du vil slette denne?',
        body2: 'Er du sikker på at du vil slette denne?',
      },
      deleteAppointmentModal: {
        header: 'Fjern avtale',
        body1: 'Er du sikker på at du vil slette denne?',
        body2: 'Du kan ikke slette den siste avtalen!',
      },
      bookAppointmentModal: {
        header: 'Book time',
        id: 'Fødselsnummer',
        searchPlaceholder: 'Skriv inn fødselsnummer',
        noMatch: 'Ingen treff for',
        selectAppointmentTime: 'Velg avtaletid',
        nextAppointment: 'Denne giveren har allerede en reservert time',
        statusNotMatch: 'Giver er ikke kvalifisert til å reservere denne timen',
        titerNotMatch: 'Brukertiter samsvarer ikke med avtalen',
        donorTypeNotMatch: 'Donortype samsvarer ikke med avtale',
        inQuarantine: 'Bruker i karantene',
      },
      cancelBookingModal: {
        header: 'Avbestill booking?',
        body: 'Den reserverte bookingen vil bli forkastet. Er du sikker på at du vil fortsette?',
        selectAppointmentTime: 'Velg avtaletid',
      },
      counter: {
        pending: 'Venter',
        reserved: 'Reservert',
        archived: 'Arkivert'
      },
    },
    recurrence: {
      header: 'Innstillinger for gjentakelse',
      daily: 'Daglig',
      occur: 'Oppstår',
      everyDay: 'Hver ukedag',
      every: 'Hver',
      days: 'dager',
      weekly: 'Ukentlig',
      weeks: 'uke(r)',
      on: 'På',
      monthly: 'Månedlig',
      months: 'måned',
      onDay: 'På dagen',
      onThe: 'På',
      First: 'Første',
      Second: 'Andre',
      Third: 'Tredje',
      Fourth: 'Fjerde',
      Last: 'Siste',
      ends: 'Ender',
      by: 'Ender',
      after: 'Etter',
      occurrences: 'forekomster',
      noEndDate: 'Ingen slutt dato',
    },
    exceptions: {
      header: 'Innstillinger for unntak',
      excludeDate: 'Ekskluder dato',
      addException: 'Legg til unntak',
      dates: 'Datoer',
      clearAll: 'Fjern alle',
      excludeHours: 'Ekskluder tid',
      from: 'Fra',
      to: 'Til',
      onThe: 'På',
      nonworkingHours: 'Ekskludert',
      datesAndHours: 'Tid og dato',
      excludeHoursOnDate: 'Ekskluder tid på dato',
    },
  },

  dashboard: {
    tasks: 'OPPGAVER',
    findDonor: 'Søk giver',
    search: 'Søk',
    available: 'Ledig',
    reserved: 'Reservert',
    signups: 'Innmeldinger',
    attendance: 'Fremmøte',
  },

  donorDetails: {
    header: 'Giverdetaljer',
    idNumber: 'Fødselsnummer',
    lastDraw: 'Siste avtale',
    noLastDraw: 'Ingen tidligere avtale',
    quarantine: 'Karantene',
    quarantineExpires: 'Karantene utløper',
    noQuarantineDate: 'Ingen karantene',
    description: 'Beskrivelse',
    nextAppointment: 'Neste avtale',
    donorContacts: 'Kontaktdetaljer',
    address: 'Adresse',
    postalCode: 'Postnummer',
    city: 'Sted',
    email: 'E-post',
    phone: 'Telefonnummer',
    becs: 'Opprettet i Blodbanksystemet',
    appuser: 'APP-bruker',
    sms: 'SMS',
  },

  showUp: {
    header: 'Møtt til time',
    yes: 'Ja',
    no: 'Nei',
  },

  common: {
    quarantineDue: 'Karantene utløper',
    confirm: 'Bekreft',
    book: 'Book',
    cancel: 'Avbryt',
    save: 'Lagre',
    delete: 'Slett',
    proceed: 'Fortsett',
    ok: 'Ok',
    all: 'Alle',
    bloodType: 'Blodtype',
    donorType: {
      header: 'Givertype',
      full_blood: 'Fullblod',
      apheresis: 'Aferese',
    },
    titer: {
      low: 'Lav',
      high: 'Høy',
      unknown: 'Ukjent',
      title: 'Titer (anti -A og -B)',
    },
    appointmentType: {
      header: 'Avtaletype',
      'New registration': 'Nyregistrering',
      'First time draw': 'Førstegangs tapping',
      'N\'th time draw': 'Vanlig tapping',
      'Erythrocytapheresis': 'Erytrocytter',
      'Plateletpheresis': 'Trombocytter',
      'Plasmapheresis': 'Plasma',
    },
    appUserStatus: {
      header: 'APP-bruker',
      yes: 'Ja',
      no: 'Nei',
    },
    smsStatus: {
      header: 'SMS',
      true: 'Ja',
      false: 'Nei',
    },
    donorStatus: {
      header: 'Giverstatus',
      new: 'Ny',
      approved: 'Godkjent',
      established: 'Etablert',
    },
    month: {
      January: 'Januar',
      February: 'Februar',
      March: 'Mars',
      April: 'April',
      May: 'Mai',
      June: 'Juni',
      July: 'Juli',
      August: 'August',
      September: 'September',
      October: 'Oktober',
      November: 'November',
      December: 'Desember',
      Jan: 'Jan',
      Feb: 'Febr',
      Mar: 'Mars',
      Apr: 'April',
      Jun: 'Juni',
      Jul: 'Juli',
      Aug: 'Aug',
      Sep: 'Sept',
      Oct: 'Okt',
      Nov: 'Nov',
      Dec: 'Des',
    },
    day: {
      Sunday: 'Søndag',
      Monday: 'Mandag',
      Tuesday: 'Tirsdag',
      Wednesday: 'Onsdag',
      Thursday: 'Torsdag',
      Friday: 'Fredag',
      Saturday: 'Lørdag',
      Sun: 'Søn',
      Mon: 'Man',
      Tue: 'Tirs',
      Wed: 'Ons',
      Thu: 'Tor',
      Fri: 'Fre',
      Sat: 'Lørd',
      Su: 'Sø',
      Mo: 'Ma',
      Tu: 'Ti',
      We: 'On',
      Th: 'To',
      Fr: 'Fr',
      Sa: 'Lø',
    }
  },

  pagination: {
    previous: 'Forrige',
    next: 'Neste',
    rows_amount: 'Antall rader',
    of: 'av',
    all: 'alle',
  },

  table: {
    no_data: 'Det er ingen data',
    no_match: 'Det er ingen match til',
    try_another: 'Prøv et nytt søk',
    name: 'Navn',
    ID_number: 'Fødselsnummer',
    last_draw: 'Siste avtale',
    blood_type: 'Blodtype',
    titer: 'Titer',
    donor_status: 'Giverstatus',
    donor_type: 'Givertype',
    quarantine_due_date: 'Karantene utløper',
    app_user: 'APP-bruker',
    sms: 'SMS',
    next_appointment: 'Neste avtale',

    id: 'ID',
    date: 'Dato / KI.',
    title: 'Tittel',
    message: 'Melding',
    signature: 'Signatur',
    candidate: 'Kandidat',

    message_id: 'Melding ID',
    receiver_id: 'Mottaker ID',
    category: 'Category',
  },

  msgs: {
    index: 'Index',
    id: 'ID',
    date: 'Dato / KI.',
    title: 'Tittel',
    message: 'Melding',
    authorized_user: 'Pålogget bruker',
    send: 'Send',
    candidates_amount: 'Antall kandidater',
    search: 'Søk',
    admin: 'Admin',
    system_sent: 'Auto',
    system_scheduled: 'Planlagt',
    filter_settings: 'Innstillinger for filter',
    filter: 'Filter',
    message_id: 'Melding ID',
    receiver_id: 'Mottaker ID',

    tooltip: {
      bloodbank: 'Blodbank',
      blood_type: 'Blodtype',
      titer: 'Titer',
      donor_status: 'Giverstatus',
      app_user: 'APP-bruker',
      sms: 'SMS',
    }
  },
}

export default noTranslation;
